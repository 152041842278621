import { FC } from 'react';
import styled from 'styled-components';

interface TabsProps {
  children: JSX.Element | JSX.Element[]
}

interface TabProps {
  children: any,
  isActive?: boolean,
  onClick?: () => void
}

const TabWrapper = styled.ul`
  height: 45px;
  margin: 10px 0 0;
  padding: 0 0 0 20px;
  position: relative;
  border-bottom: 1px solid rgb(189, 189, 189);
  user-select: none;
`;

const TabElement = styled.li`
  float: left;
  max-width: 360px;
  cursor: pointer;
  border: 1px solid rgb(189, 189, 189);
  height: 45px;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 6px 0px 0px;
  border-radius: 4px 4px 0px 0px;
  font-size: 14px;

  & svg {
    width: 16px;
    height: 16px;
    margin: 1px 10px 0px -3px;
  }

  &.active {
    background-color: #fff;
    color: rgb(62, 120, 187);
    border-bottom: none;

    & svg {
      fill: rgb(62, 120, 187);
    }
  }
`;

/**
 * Render a single tab
 * @returns {JSX.Element} Component template
 */
export const Tab: FC<TabProps> = ({ children, isActive, onClick}) => {

  return (
    <TabElement className={isActive ? "active" : undefined} onClick={onClick}>
      {children}
    </TabElement>
  );
}

/**
 * Render a tab wrapper
 * @returns {JSX.Element} Component template
 */
export const Tabs: FC<TabsProps> = ({ children }) => {

  return (
    <TabWrapper>
      {children}
    </TabWrapper>
  );
}