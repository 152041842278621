import { FC } from 'react';
import styled from 'styled-components';

interface TagFieldProps {
    tags?: string[],
    label?: string
}

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #eee;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 2px 2px 0 0;
  padding: 25px 12px 8px;
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.focus label {
    color: ${p => p.theme?.accentColor};
  }
`;

const Tag = styled.div`
  background-color: ${p => p.theme.accentColor};
  color: #fff;
  padding: 2px 8px;
  border-radius: 15px;
  display: inline;
  margin: 0 8px 8px 0;
  font-size: 12px;
  user-select: none;
`;

const Label = styled.label`
  position: absolute;
  left: 0px;
  top: 0px;
  user-select: none;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  transform-origin: left top;
  transform: translate(12px, 7px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &:focus {
    border-bottom: 1px solid ${p => p.theme?.accentColor};
  }
`;

/**
 * Render a text field
 * @returns {JSX.Element} Component template
 */
const TagField: FC<TagFieldProps> = ({tags, label}) => {

  return (
    <Container>
      <Label>{label}</Label>
      {tags?.map((tag: string, i: number) => {
        return <Tag key={`tag${i}`}>{tag}</Tag>
      })}
    </Container>
  );
}

export default TagField;