import { FC } from "react";
import { keyframes, styled } from "styled-components";

interface LoaderProps {
  className?: string
}

const RippleEffect = keyframes`
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
`;

const Ripple = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  &::nth-child(2) {
    animation-delay: -0.5s;
  }

  & div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: ${RippleEffect} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  & div:nth-child(2) {
    animation-delay: -0.5s;
  }
  `;


/**
 * Render a simple loading component
 * @returns {JSX.Element} Component template
 */
const Loader: FC<LoaderProps> = ({className}) => {
  return (
    <Ripple className={className}>
      <div>
        </div><div>
      </div>
    </Ripple>
  );
}

export default Loader;