import { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { AppContext } from './contexts/AppContext';
import Header from './components/Header';
import EditorView from './views/Editor';
import PreviewView from './views/Preview';

const Wrapper = styled.main`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
`;

function App() {
  const [theme, setTheme] = useState<any>({});
  const [formData, setFormData] = useState<any>();
  const [currentLanguage, setCurrentLanguage] = useState<string>("no");

  // Define theme
  useEffect(() => {
    setTheme({
      accentColor: "#3e78bb"
    });
  }, []);

// Generate some simple placeholder data
useEffect(() => {
  setFormData(
    {
      "formData": {
        "content": {
          "blocks": {
            "no": [
              {
                "blockType": "byline",
                "byline": {
                  "description": "Historiker",
                  "image": {
                    "mediaId": 40851
                  },
                  "name": "Rudolf Fjellvik",
                  "prefix": "Av"
                }
              },
              {
                "blockType": "text",
                "body": "<p class=\"editor_paragraph\" dir=\"ltr\" style=\"text-align: start;\"><span>Den moderne nissen har tilpasset seg det travle og teknologiske samfunnet vi lever i. Nå er ikke lenger nissen begrenset til å overvåke barna med en liste og sjekke den to ganger – han gjør alt digitalt. </span></p><p class=\"editor_paragraph\" dir=\"ltr\"><span>Den lille, skjeggete figuren har adoptert mobiltelefonen som sitt viktigste verktøy for å holde oversikt over hvem som har vært snille eller rampete i løpet av året.</span></p>",
                "showTitle": false,
                "title": "Info om den moderne nissen"
              },
              {
                "blockType": "tasks",
                "taskItems": [
                  {
                    "answers": [
                      {
                        "answer": "2 år"
                      },
                      {
                        "answer": "10 år"
                      },
                      {
                        "answer": "10000 år",
                        "correct": true
                      }
                    ],
                    "cover": {
                      "mediaId": 40847
                    },
                    "question": "Hvor gammel er den moderne nissen?",
                    "resultFeedback": "Denne nissen er faktisk over 10.000 år!",
                    "taskType": "quiz"
                  },
                  {
                    "answer": "TOMTE",
                    "cover": {
                      "mediaId": 40851
                    },
                    "question": "Hva er dette?",
                    "resultFeedback": "Dette er en tomte!",
                    "taskType": "wordgame"
                  }
                ],
                "title": "Oppgaver"
              },
              {
                "audioItems": [
                  {
                    "mediaId": 40844
                  }
                ],
                "blockType": "audio"
              },
              {
                "blockType": "text",
                "body": "<p class=\"editor_paragraph\" dir=\"ltr\" style=\"text-align: start;\"><span>Med sin smarttelefon i hånden navigerer den moderne nissen gjennom sosiale medier for å holde seg oppdatert på barnas siste oppførsel. Han tar selfier med glade barn som sover fredelig, og legger ut oppdateringer om at \"Julemagien er på vei!\" eller \"Siste sjekk før julenatt!\"</span></p>"
              },
              {
                "blockType": "media",
                "layout": "compare",
                "mediaItems": [
                  {
                    "mediaId": 40846
                  },
                  {
                    "mediaId": 40842
                  }
                ],
                "showTitle": true,
                "title": "Sommeren vs vinteren"
              },
              {
                "blockType": "dm",
                "dmBlockType": "search",
                "dmSearch": {
                  "query": "tomte",
                  "resultCount": 4
                },
                "showTitle": true,
                "title": "Dynamisk treff fra DM"
              },
              {
                "3dItems": [
                  {
                    "mediaId": 40843
                  }
                ],
                "blockType": "3d"
              },
              {
                "blockType": "minne",
                "showTitle": true,
                "title": "Minner",
                "topicId": "733bec50-325b-4710-bb8c-0ebe0f1909e1"
              }
            ]
          }
        },
        "general": {
          "image": {
            "filters": {},
            "focalPoint": {},
            "mediaId": 40849
          },
          "internalTitle": "Den moderne nissen",
          "listteaser": {
            "no": "Den moderne nissen bruker mobiltelefonen til å overvåke barna, organisere gaver og spre julemagi i en digital tidsalder."
          },
          "preface": {
            "no": "Stadig flere nisser skaffer seg smarttelefon for å kunne bruke KulturPunkt."
          },
          "title": {
            "no": "Den moderne nissen"
          }
        },
        "metadata": {
          "license": "none",
          "location": {
            "lat": 59.91300333071042,
            "lng": 10.741675636027107
          },
          "subjects": [
            "hagenisse"
          ]
        }
      },
      "locale": "no",
      "schemas": { "schema_id": 2 }
    }
  );
}, []);

  return (
    <AppContext.Provider value={{ formData, setFormData, currentLanguage, setCurrentLanguage }}>
      <ThemeProvider theme={theme}>
        <Header/>
        <Wrapper>
          <EditorView />
          <PreviewView />
        </Wrapper>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
