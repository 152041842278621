import { FC } from 'react';
import styled from 'styled-components';

interface ImageProps {
    src?: string,
    mediaId?: number,
    aspectRatio?: string
}

const ImageElement = styled.img<{$aspectRatio?: string}>`
  width: 100%;
  aspect-ratio: ${p => p.$aspectRatio};
  background-color: #eee;
  border-radius: 2px;
`;

const ImagePlaceholderElement = styled.div<{$aspectRatio?: string}>`
  width: 100%;
  aspect-ratio: ${p => p.$aspectRatio};
  background-color: #eee;
  border-radius: 2px;
`;

/**
 * Render an image component
 * @returns {JSX.Element} Component template
 */
const Image: FC<ImageProps> = ({src, aspectRatio}) => {

  if (src) {
    return (
      <ImageElement src={src} alt="" $aspectRatio={aspectRatio || "1/1"}/>
    );
  } else {
    return (
      <ImagePlaceholderElement $aspectRatio={aspectRatio || "1/1"}/>
    );
  }
}

export default Image;