import { FC, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from '../assets/chevron_right.svg';
import { AppContext } from '../contexts/AppContext';
import Icon from './Icon';
import Dropdown from './Dropdown';

const HeaderWrapper = styled.header`
  position: fixed;
  width: 100%;
  background-color: #fff;
  align-items: center;
  box-shadow: 0 1px 0 rgb(189, 189, 189);
  display: flex;
  justify-content: space-between;
  height: 60px;
  z-index: 1;
`;

const Left = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  max-width: calc(100% - 400px);
`;

const Right = styled.div`
  margin-right: 20px;
  text-align: right;
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 18px;
  font-weight: 700;
  padding: 0 5px 0 0;
  margin: 0;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ChevronIcon = styled(ChevronRight)`
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: ${p => p.theme.accentColor};
  float: left;
  margin-right: 5px;

  &.onlyWide {
    @media (max-width: 800px) {
      display: none;
    }
  }
`;

const DocumentStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  float: left;
  margin-right: 30px;
  height: 32px;
`;

const PublishStatus = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: ${p => p.theme.accentColor};
  margin-right: 30px;
`;

const PublishDate = styled.span`
  font-weight: bold;
`;

const Author = styled.span`
`;

const SaveButton = styled.button`
  border-radius: 2px;
  background-color: ${p => p.theme.accentColor};
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  float: right;
  justify-content: flex-end;
  padding: 0 18px;
  border: none;
  cursor: pointer;
  height: 32px;
  box-shadow: 0 2px 0px #00000010;

  &.dropdown {
    padding: 2px 5px 0;
    margin-left: 0.5px;
  }
`;

const DropdownElement = styled(Dropdown)`
  margin-left: 20px;
`;

/**
 * Render a header
 * @returns {JSX.Element} Component template
 */
const Header: FC = () => {
  const { formData, setFormData, setCurrentLanguage } = useContext(AppContext);

  /**
   * Save data
   */
  const saveData = useCallback(() => {
    alert("Ikke implementert");
  }, []);

  /**
   * Set editor language
   * @param {object} e Event from language selector 
   */
  const setLanguage = useCallback((language: string|number) => {
    setCurrentLanguage(language);
    setFormData({...formData, ...{locale: language}});
  }, [formData, setFormData, setCurrentLanguage]);

  const emptyFormData = useCallback(() => {
    setFormData({...formData, ...{ formData: {} }});
  }, [setFormData, formData]);

  return (
    <HeaderWrapper>
      <Left>
        <Title className="onlyWide">Kulturio Admin</Title>
        <ChevronIcon className="onlyWide"/>
        <Title className="onlyWide">Artikler</Title>
        <ChevronIcon className="onlyWide"/>
        <Title>{formData?.formData?.general?.title?.[formData?.locale || "no"] || "Artikkel"}</Title>
        <DropdownElement defaultValue={formData?.locale} list={[
          { value: "no", label: "Norsk", marked: formData?.formData?.content?.blocks?.["no"]}, 
          { value: "sv", label: "Svensk", marked: formData?.formData?.content?.blocks?.["sv"] }, 
          { value: "en", label: "Engelsk", marked: formData?.formData?.content?.blocks?.["en"] }, 
          { value: "de", label: "Tysk", marked: formData?.formData?.content?.blocks?.["de"] }, 
          { value: "es", label: "Spansk", marked: formData?.formData?.content?.blocks?.["es"] }, 
          { value: "fr", label: "Fransk", marked: formData?.formData?.content?.blocks?.["fr"] }, 
          { value: "sma", label: "Nordsamisk", marked: formData?.formData?.content?.blocks?.["sma"] }, 
          { value: "se", label: "Sørsamisk", marked: formData?.formData?.content?.blocks?.["se"] }
        ]} onChange={setLanguage}/>
      </Left>
      <Right>
        <PublishStatus>Publisert</PublishStatus>
        <DocumentStatus className="onlyWide">
          <PublishDate>4 timer siden</PublishDate>
          <Author>av Rudolf Fjellvik</Author>
        </DocumentStatus>
        <SaveButton onClick={emptyFormData}>Tøm</SaveButton>
        <SaveButton onClick={saveData} className="dropdown"><Icon>arrow_drop_down</Icon></SaveButton>
      </Right>
    </HeaderWrapper>
  );
}

export default Header;