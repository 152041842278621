import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronDown } from '../assets/chevron_down.svg';

interface ModuleProps {
  title: string,
  expanded?: boolean,
  children: any
}

const Wrapper = styled.section`
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;

  &:not(:first-child) {
    border-top: none;
  }

  &.open::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-left: 4px solid ${p => p.theme.accentColor};
  }
`;

const ModuleHead = styled.div`
  padding: 16px 20px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
`;

const ModuleBody = styled.div`
  padding: 0 20px 20px;
`;

const Chevron = styled(ChevronDown)`
  width: 12px;
  margin-right: 15px;
  fill: #757575;

  &.open {
    transform: rotate(180deg);
  }
`;

/**
 * Render a module
 * @returns {JSX.Element} Component template
 */
const Module: FC<ModuleProps> = ({ title, expanded, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(expanded || false);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  
  return (
    <Wrapper className={isOpen ? "open" : undefined}>
      <ModuleHead onClick={toggleOpen}><Chevron className={isOpen ? "open" : undefined}/>{title}</ModuleHead>
      {isOpen && (
        <ModuleBody>
        {children}
        </ModuleBody>
      )}
    </Wrapper>
  );
}

export default Module;