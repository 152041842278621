import OpenAI from 'openai';
import blockSchema from './blockSchema.json'

/**
 * Forward question from prompt to the Open AI API. 
 * @param {string} prompt Text from the user
 * @param {object} currentLanguage Existing form data to include (optional)
 * @param {object} formData Existing formdata
 */
export const askAI = async (prompt: string, currentLanguage?: any, formData?: any) => {
    if (prompt?.length < 2) { return }

    const openai = new OpenAI({ apiKey: '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', dangerouslyAllowBrowser: true });

    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo-1106", // or 'gpt-4', 'gpt-3.5-turbo-1106', etc
      messages: [
        { role: "system", content: `Du er en hjelpsom assistent som kan generere innhold til digitale guider og skjermer i utstillinger. Alle tema kan være aktuelle. Lesermålgruppen er besøkende på museer i Norge og Sverige. Du skal kun produsere reelt innhold.`},
        { role: "system", content: `Innhold kan skapes på norsk (no), svensk (sv), engelsk (en), tysk (de), spansk (es), fransk (fr), nordsamisk (sma) og sørsamisk (se). Du skal kun skape innhold på språket med språkkode '${currentLanguage}', uavhengig av hvilket språk brukeren kommuniserer med.` },
        { role: "system", content: `Du kan kun svare med RFC8259-gyldig JSON. Bruk følgende JSON-schema: ${JSON.stringify(blockSchema, null, 2)}` },
        { role: "system", content: `Innholdet du produserer bygger videre på følgende JSON: ${formData}` },
        { role: "user", content: prompt }],
      response_format: { "type": "json_object" }, // Only supported by 'gpt-3.5'
    })
    
    if (response) {
      const generatedText = decodeURIComponent(response?.choices?.[0]?.message?.content || "");

      try {
          return JSON.parse(generatedText);
      } catch(e) {
          console.error("Could not parse response from OpenAI", e);
          return { success: false };
      }
    } else {
      console.log(response)
      return { success: false };
    }
  }