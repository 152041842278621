import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import Loader from '../components/Loader';

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  background: #333;
  padding: 10px;
  overflow: hidden;

  @media (max-width: 800px) {
    display: none;
  }
`;

const PreviewFrame = styled.iframe`
  position: relative;
  width: 100%;
  height: 100%;
  border: none; 
  background-color: #dcdcdc; 
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  transform-origin: 0 0;
  opacity: 0;

  &.visible {
    opacity: 1;
    transition: opacity .5s;
  }
`;

const ButtonWrapper = styled.ul`
  position: absolute; 
  bottom: 20px; 
  left: 50%; 
  transform: translateX(-50%); 
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
`;
const Button = styled.li`
  list-style: none;
  font-size: .6em;
  text-align: center;
  float: left; 
  color: #000; 
  background: #fff; 
  padding: 5px 10px; 
  border-radius: 20px; 
  text-decoration: none; 
  margin: 5px;
  cursor: pointer;

  &.active {
    background-color: rgb(62, 120, 187);
    color: #fff;
  }
`;

const CenteredLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const EditorTextArea = styled.textarea`
  color: #fff;
  background-color: #333;
  border-radius: 4px;
  border: none;
  width: 100%;
  height: calc(100vh - 120px);
  padding: 0px;
  resize: none;
`;

const previews = [
  { name: "KulturPunkt", url: "https://kulturpunkt.org/preview?env=prod", width: 375, height: 812 },
  { name: "Kulturio Kiosk", url: "https://kiosk.kulturio.org/?env=prod#/preview", width: 1920, height: 1080 },
  { name: "JSON", show: "json"}
];

/**
 * Render the preview area
 * @returns {JSX.Element} Component template
 */
const PreviewView: FC = () => {
  const { formData, setFormData } = useContext(AppContext);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const jsonEditorRef = useRef<HTMLTextAreaElement>(null);
  const [currentPreview, setCurrentPreview] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Scale iframe based on width and height
   * @param {number} width Pixel width of device
   * @param {number} height Pixel height of device
   */
  const scaleIframe = useCallback((width?: number, height?: number) => {
    let scale;
    if (!iFrameRef.current || !iFrameRef.current.parentElement || !width || !height) { return; }

    // Calculate scale
    if (width > height) {
      scale = (iFrameRef.current.parentElement?.clientWidth - 100) / width;
    } else {
      scale = (iFrameRef.current.parentElement?.clientHeight - 150) / height;
    }

    // Scale iframe
    iFrameRef.current.style.transform = `scale(${scale}) translate(-50%, -50%)`;
    iFrameRef.current.style.width = `${width}px`;
    iFrameRef.current.style.height = `${height}px`;
  }, []);

  /**
   * Run when iframe is loaded
   */
  const iframeIsLoaded = useCallback(() => {
    setTimeout(() => {
      iFrameRef.current && iFrameRef.current.contentWindow?.postMessage(JSON.stringify(formData), "*");
    }, 1000, formData);
    setTimeout(() => {
      iFrameRef.current && iFrameRef.current.contentWindow?.postMessage(JSON.stringify(formData), "*");
    }, 2000, formData);
  }, [formData]);

  /**
   * Update formdata when text area content changes
   * @param {event} Textarea change event
   */
  const updateFormData = useCallback((e: any) => {
    if (!e?.target?.value) { return }

    try {
      setFormData(JSON.parse(e?.target?.value));
    } catch(e) {

    }
  }, [setFormData]);

  useEffect(() => {
    if (!previews[currentPreview]?.url) { return }

    setIsLoading(true);
    scaleIframe(previews[currentPreview].width, previews[currentPreview].height);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    // @ts-ignore
  }, [currentPreview, scaleIframe, setIsLoading]);

  /**
   * Perform iframe scale on startup. Listen for window resize
   */
  useEffect(() => {
    window.addEventListener("resize", () => {
      scaleIframe(previews[currentPreview].width, previews[currentPreview].height);
    });
  }, [scaleIframe, currentPreview]);

  /**
   * Post formdata to iframe on update
   */
  useEffect(() => {
    if (iFrameRef.current) {
      iFrameRef.current.contentWindow?.postMessage(JSON.stringify(formData), "*");
    }
  }, [formData]);

  /**
   * Update JSON editor when formdata updates
   */
  useEffect(() => {
    if (jsonEditorRef.current) {
      jsonEditorRef.current.value = JSON.stringify(formData, undefined, 2);
    }
  }, [formData]);

  return (
    <Wrapper>
      {isLoading && (<CenteredLoader/>)}
      {previews[currentPreview]?.url && (
        <PreviewFrame src={previews[currentPreview]?.url} ref={iFrameRef} onLoad={iframeIsLoaded} className={!isLoading ? "visible" : undefined}/>
      )}
      {previews[currentPreview]?.show === "json" && (
        <EditorTextArea ref={jsonEditorRef} onChange={updateFormData} defaultValue={JSON.stringify(formData, undefined, 2)}/>
      )}
      <ButtonWrapper>
        {previews.map((preview: any, i: number) => {
          return <Button className={currentPreview === i ? "active" : undefined} key={`button${i}`} onClick={() => { setCurrentPreview(i) }}>{preview?.name}</Button>
        })}
      </ButtonWrapper>
    </Wrapper>
  );
}

export default PreviewView;