import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowDropdown from '../assets/arrow_dropdown.svg';

interface DropdownProps {
  defaultValue?: string|number,
  list?: { value: string, label: string, marked?: boolean }[],
  onChange?: (value: string|number) => void,
  className?: string
}

const Container = styled.div`
  position: relative;
`;

const Button = styled.button`
  appearance: none;
  min-width: 120px;
  display: inline-flex;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  padding: 8px 10px;
  background: #fff url(${ArrowDropdown}) no-repeat right 50%;
  cursor: pointer;
  outline: none;

  &.expanded {
    background: none;
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  left: 0;
  top: 35px;
  margin: 0;
  padding: 5px 0;
  background: #fff;
  border-radius: 2px;
  list-style: none;
  font-size: .85em;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
`;

const Item = styled.li`
  padding: 6px 16px;
  border-left: 4px solid transparent;
  cursor: pointer;

  &.active {
    border-left: 4px solid ${p => p.theme.accentColor};
  }

  &.marked {
    font-weight: bold;
  }
`;

/**
 * Render a dropdown field
 * @returns {JSX.Element} Component template
 */
const Dropdown: FC<DropdownProps> = ({defaultValue, list, onChange, className}) => {
  const [currentValue, setCurrentValue] = useState<string|number>();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  // Set default value on first render
  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue, setCurrentValue]);

  /**
   * Toggle visibility of dropdown
   */
  const toggleDropdown = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [setIsExpanded, isExpanded]);

  /**
   * Item has been selected on click
   * @param {string} value Selected value
   */
  const selectItem = useCallback((value: string|number) => {
    setCurrentValue(value);
    setIsExpanded(false);
    onChange && onChange(value);
  }, [onChange, setCurrentValue]);

  return (
    <Container className={className}>
      <Button  className={isExpanded ? "expanded" : undefined} onClick={toggleDropdown}>{list?.filter(el => el?.value === currentValue)?.[0]?.label || ""}</Button>
      { isExpanded && (
        <DropdownList>
          { list?.map((item: any, i: number) => {
            return <Item key={`item${i}`} className={`${item?.value === currentValue ? "active" : undefined} ${item?.marked && `marked`}`} onClick={() => selectItem(item?.value)}>{item?.label}</Item>
          }) }
        </DropdownList>
      ) }
    </Container>
  );
}

export default Dropdown;