import { FC, useCallback, useContext, useState } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Tabs, Tab } from '../components/Tabs';
import styled from 'styled-components';
import Section from '../components/Section';
import Module from '../components/Module';
import ChatPrompt from '../components/AI-GeneralChatPrompt';
import Icon from '../components/Icon';
import Image from '../components/Image';
import { ReactComponent as ArticleIcon } from '../assets/icon_article.svg';
import { ReactComponent as CogIcon } from '../assets/icon_cog.svg';
import { ReactComponent as DMIcon } from '../assets/icon_dm.svg';
import { ReactComponent as MinneIcon } from '../assets/icon_minne.svg';
import TextField from '../components/TextField';
import TextArea from '../components/TextArea';
import TagField from '../components/TagField';
import BlockChatPrompt from '../components/AI-BlockChatPrompt';

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  background-color: #eee;
  padding-top: 8px;
  overflow-y: auto;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;

  &::after {
    content: "";
    flex: auto;
  }
`;

const BlockButton = styled.button`
  cursor: pointer;
  user-select: none;
  border: 1px solid rgba(62, 120, 187, 0.5);
  color: ${p => p.theme.accentColor};
  min-width: 64px;
  padding: 8px 15px;
  border-radius: 2px;
  background-color: #fff;
  flex: auto;
  flex-grow: 0.2;

  & span, svg {
    float: left;
    line-height: 0.7;
    margin-right: 5px;
  }

  & svg {
    width: 18px;
    height: 16px;
    fill: ${p => p.theme.accentColor};
  }

  &.reverse {
    background-color: ${p => p.theme.accentColor};
    color: #fff;
  }

  &.reverse svg {
    fill: #fff;
  }
`;

const Grid = styled.div<{$colspan?: number}>`
  grid-column-end: span ${(p) => p.$colspan};
  align-items: start;
`;

/**
 * Render a simple editor
 * @returns {JSX.Element} Component template
 */
const EditorView: FC = () => {
  const { formData } = useContext(AppContext);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [showAIBlockAssistant, setShowAIBlockAssistand] = useState<boolean>(false);

  /**
   * Generate block module titles based on block data
   * @param {object} block Block data from form
   * @returns {string} Block data to be used in module
   */
  const getBlockTitle = useCallback((block: any) => {
    let blockName: string = "";

    switch (block?.blockType) {
      case "text": blockName = "Tekst"; break;
      case "media": blockName = "Media"; break;
      case "audio": blockName = "Lyd"; break;
      case "tasks": blockName = "Oppgaver"; break;
      case "quote": blockName = "Sitat"; break;
      case "dm": blockName = "DM"; break;
      case "minne": blockName = "Minne"; break;
      case "byline": blockName = "Byline"; break;
      case "3d": blockName = "3D"; break;
      case "widget": blockName = "Widget"; break;
      case "links": blockName = "Lenker"; break;
    }

    if (block?.title?.length > 0) {
      blockName += `: ${block?.title}`;
    }

    return blockName;
  }, []);
  
  return (
    <Wrapper>
      <Tabs>
        <Tab isActive={currentTab === 0} onClick={() => setCurrentTab(0)}><ArticleIcon/>Artikkel</Tab>
        <Tab isActive={currentTab === 1} onClick={() => setCurrentTab(1)}><CogIcon/>Metadata</Tab>
      </Tabs>
      <Section title={`Generelt`} grid expanded hidden={currentTab !== 0}>
        <Grid $colspan={4}>
          <Image aspectRatio="1/1" />
        </Grid>
        <Grid $colspan={8}>
          <TextField label="Tittel" value={formData?.formData?.general?.title?.[formData?.locale] || ""}/>
          <TextField label="Listebeskrivelse" value={formData?.formData?.general?.listteaser?.[formData?.locale] || ""}/>
          <TextArea label="Ingresstekst" value={formData?.formData?.general?.preface?.[formData?.locale] || ""}/>
        </Grid>
      </Section>
      <Section title={`Innhold`} hidden={currentTab !== 0}>
        {formData?.formData?.content?.blocks?.[formData?.locale || "no"]?.map((block: any, i: number) => {
          return <Module key={`module${i}`} title={getBlockTitle(block)}><p>Ikke implementert</p></Module>
        })}

        <ButtonWrapper>
          <BlockButton><Icon>article</Icon>Tekst</BlockButton>
          <BlockButton><Icon>photo_size_select_actual</Icon>Bilder/video</BlockButton>
          <BlockButton><Icon>audiotrack</Icon>Lyd</BlockButton>
          <BlockButton><DMIcon/>DigitaltMuseum</BlockButton>
          <BlockButton><MinneIcon/>Minne</BlockButton>
          <BlockButton><Icon>3d_rotation</Icon>3D</BlockButton>
          <BlockButton><Icon>attach_file</Icon>PDF-dokumenter</BlockButton>
          <BlockButton><Icon>link</Icon>Lenker</BlockButton>
          <BlockButton><Icon>lightbulb</Icon>Oppgaver</BlockButton>
          <BlockButton><Icon>person_pin</Icon>Byline</BlockButton>
          <BlockButton><Icon>format_quote</Icon>Sitat</BlockButton>
          <BlockButton><Icon>code</Icon>Widget</BlockButton>
          <BlockButton><Icon>anchor</Icon>Snarvei</BlockButton>
          <BlockButton className="reverse" onClick={() => setShowAIBlockAssistand(true)}><Icon>auto_fix_high</Icon>KI-assistent</BlockButton>
        </ButtonWrapper>
      </Section>
      <Section title={`Sted`} grid hidden={currentTab !== 1}>
        <Grid $colspan={4}>
          <Image aspectRatio="1/1" />
        </Grid>
        <Grid $colspan={8}>
          <TextField label="Breddegrad" value={formData?.formData?.metadata?.location?.lat || ""}/>
          <TextField label="Lengdegrad" value={formData?.formData?.metadata?.location?.lng || ""}/>
        </Grid>
      </Section>
      <Section title={`Metadata`} hidden={currentTab !== 1}>
        <TagField label="Emneord" tags={formData?.formData?.metadata?.subjects}/>
      </Section>
      <Section title={`Begrenset tilgang`} hidden={currentTab !== 1}>
        <p>Ikke implementert</p>
      </Section>
      <ChatPrompt/>
      <BlockChatPrompt isVisible={showAIBlockAssistant} setIsVisible={setShowAIBlockAssistand}/>
    </Wrapper>
  );
}

export default EditorView;