import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronDown } from '../assets/chevron_down.svg';

interface SectionProps {
  title: string,
  expanded?: boolean,
  children: JSX.Element | JSX.Element[],
  grid?: boolean,
  hidden?: boolean
}

const Wrapper = styled.section`
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;

  &.open::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-left: 4px solid ${p => p.theme.accentColor};
  }
`;

const SectionHead = styled.div`
  padding: 17px 20px;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
`;

const SectionBody = styled.div`
  padding: 0 20px 20px;

  &.grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: min-content;
  }
`;

const Chevron = styled(ChevronDown)`
  width: 12px;
  margin-right: 15px;
  fill: #757575;

  &.open {
    transform: rotate(180deg);
  }
`;

/**
 * Render a section
 * @returns {JSX.Element} Component template
 */
const Section: FC<SectionProps> = ({ title, expanded, children, hidden, grid }) => {
  const [isOpen, setIsOpen] = useState<boolean>(expanded || false);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  if (hidden) { return null }
  
  return (
    <Wrapper className={isOpen ? "open" : undefined}>
      <SectionHead onClick={toggleOpen}><Chevron className={isOpen ? "open" : undefined}/>{title}</SectionHead>
      {isOpen && (
        <SectionBody className={grid ? "grid" : undefined}>
        {children}
        </SectionBody>
      )}
    </Wrapper>
  );
}

export default Section;