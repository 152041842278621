import { FC } from 'react';
import styled from 'styled-components';

interface IconProps {
  children: string,
  className?: string,
  onClick?: () => void
}

const IconElement = styled.span`
`;

/**
 * Render an icon from Material
 * @returns {JSX.Element} Component template
 */
const Icon: FC<IconProps> = ({children, className, onClick}) => {

  return (
    <IconElement className={`material-icons-outlined ${className}`} onClick={onClick}>{children}</IconElement>
  );
}

export default Icon;