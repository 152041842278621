import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { askAI } from '../ai/OpenAI-Block';
import { AppContext } from '../contexts/AppContext';
import Icon from './Icon';

interface BlockChatPromptProps { 
  isVisible: boolean,
  setIsVisible?: any
}

const FadeInAnimation = keyframes`
  from { opacity: 0 }
  to { opacity: 100 }
`;

const LoaderAnimation = keyframes`
  0% { box-shadow: -6px 0, 6px 0, 18px 0; }
  33% { box-shadow: -6px -8px, 6px 0, 18px 0; }
  66% { box-shadow: -6px 0, 6px -8px, 18px 0; }
  100% { box-shadow: -6px 0, 6px 0, 18px -8px; }
`

const Prompt = styled.div`
  background-color: #fff;
  width: 400px;
  border-radius: 4px;
  position: fixed;
  z-index: 20;
  top: 50%;
  left: calc(25%);
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  overflow: hidden;
  animation: ${FadeInAnimation} 0.5s;
  display: none;
  transform: translate(-50%, -50%);

  &.visible {
    display: block;
  }

  @media (max-width: 800px) {
    left: calc(50%);
  }
`;


const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  user-select: none;
`;

const Loader = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  margin: 5px 22px 2px 8px;
  position: relative;
  color: #333;
  box-sizing: border-box;
  animation: ${LoaderAnimation} 1s linear infinite alternate;
`;

const Head = styled.div`
  background-color: rgb(62, 120, 187);
  color: #fff;
  padding: 9px 12px;
`;

const ChatWrap = styled.div`
  height: 250px;
  overflow-y: auto;
  position: relative;
  background-color: ${p => p.theme.accentColor}10;
`;

const Chat = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 5px 10px 10px;
  gap: 10px;
  position: absolute;
  bottom: 0;
`;

const SlideInFromLeft = keyframes`
  0% { margin-left: -400px; opacity: 0; }
  100% { margin-left: 0; opacity: 1; }
`;

const SlideInFromRight = keyframes`
  0% { margin-right: -400px; opacity: 0; }
  100% { margin-right: 0; opacity: 1; }
`;

const ChatBubble = styled.div`
  background-color: ${p => p.theme.accentColor}50;
  border-radius: 10px;
  padding: 8px 10px;
  font-size: 12px;
  max-width: 80%;
  float: left;
  animation: ${SlideInFromLeft} 0.3s;

  &.user {
    align-self: flex-end;
    background-color: #dddddd50;
    animation: ${SlideInFromRight} 0.3s;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  border-top: 1px solid #ddd;
  padding: 4px 8px;
`;

const TextInput = styled.input`
  background-color: #fff;
  width: 100%;
  border: none;
  outline: none;
  display: block;
  resize: none;
  font: inherit;
  font-size: 12px;
  float: left;
`;

const Title = styled.h1`
  font-size: .85em;
  font-weight: bold;
  margin: 0;
  display: inline;
`;

const SendButton = styled.button`
  background-color: #fff;
  border: none;
  padding: 5px 0 0 0;
  cursor: pointer;
  float: right;
  color: ${p => p.theme.accentColor};
  font-size: 20px;
`;

const CloseIcon = styled(Icon)`
  float: right;
  font-size: 20px;
  cursor: pointer;
`;

/**
 * Render a chat prompt
 * @returns {JSX.Element} Component template
 */
const BlockChatPrompt: FC<BlockChatPromptProps> = ({isVisible, setIsVisible}) => {
  const { formData, setFormData, currentLanguage } = useContext(AppContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogue, setDialogue] = useState<any[]>([]);

  /**
   * Add chat bubble
   * @param {string} sender Indicates if the sender is the user or the chatbot
   * @param {string} message Message to display
   */
  const addChatBubble = useCallback((sender: "user" | "machine", message: string) => {
    let cachedDialogue = dialogue;
    cachedDialogue.push({sender, message});
    setDialogue(cachedDialogue);
  }, [dialogue]);


  /**
   * Execute AI request
   */
  const executeAIRequest = useCallback((formData?: any, currentLanguage?: string) => {
    const inputValue = inputRef?.current?.value;
    console.log(currentLanguage)

    if (inputValue && inputValue.length > 0) {
      addChatBubble("user", inputValue);
      setIsLoading(true);

      (async() => {
        const data = await askAI(inputValue, currentLanguage, formData);
        console.info(data);
        
        if (data?.feedback) {
          addChatBubble("machine", data?.feedback);
        } else {
          addChatBubble("machine", "En feil har oppstått. Vennligst forsøk på nytt");
        }
        
        if (data.success && data.blockData) {
          let tempFormdata = formData?.formData;
          
          // Create block object structure if not already existing
          if (!tempFormdata) { tempFormdata = {} }
          if (!tempFormdata.content) { tempFormdata.content = {} }
          if (!tempFormdata.content.blocks) { tempFormdata.content.blocks = {} }
          if (!tempFormdata.content.blocks?.[currentLanguage || "no"]) { tempFormdata.content.blocks[currentLanguage || "no"] = [] }

          // Merge new blockdata into old structure
          let existingBlocks = tempFormdata?.content?.blocks?.[currentLanguage || "no"] || [];
          tempFormdata.content.blocks[currentLanguage || "no"] = [...existingBlocks, ...data?.blockData];
          setFormData({...formData, ...{formData: tempFormdata}})
        }

        setIsLoading(false);
      })();
      
      inputRef.current.value = "";
    }
  }, [setFormData, setIsLoading, addChatBubble]);

  /**
   * Check if user hits the enter key in the input field
   * @param {event} e onKeyDown event
   */
  const checkKey = useCallback((e: any) => {
    if (e.key === "Enter") {
      executeAIRequest(formData, currentLanguage);
    }
  }, [formData, currentLanguage, executeAIRequest]);

  // Show welcome dialogue when button is clicked
  useEffect(() => {
    if (isVisible && dialogue?.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        addChatBubble("machine", "Hei! Jeg er din KI-assistent. Hva kan jeg hjelpe deg med?");
        setIsLoading(false);
      }, 500);
    }
  }, [isVisible, dialogue, addChatBubble]);

  return (
    <>
      {isVisible && (<Overlay/>)}
      <Prompt className={isVisible ? "visible" : "hidden"}>
        <Head>
          <Title>Virtuell assistent</Title>
          <CloseIcon onClick={ () => setIsVisible(false) }>close</CloseIcon>
        </Head>
        <ChatWrap>
          <Chat ref={chatRef}>
            {dialogue?.map((line: any, i: number) => {
              return <ChatBubble key={`bubble${i}`} className={line?.sender}>{line?.message}</ChatBubble>
            })}
            {isLoading && (<ChatBubble className='machine'><Loader/></ChatBubble>)}
          </Chat>
        </ChatWrap>
        <InputWrapper>
          <TextInput autoFocus type="text" placeholder={`Hva kan jeg hjelpe deg med?`} ref={inputRef} disabled={isLoading} onKeyDown={checkKey}/>
          <SendButton onClick={() => executeAIRequest(formData, currentLanguage)} disabled={isLoading}><Icon>keyboard_return</Icon></SendButton>
        </InputWrapper>
      </Prompt>
    </>
  );
}

export default BlockChatPrompt;