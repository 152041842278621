import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface TextAreaProps {
    value?: string,
    label?: string
}

const Container = styled.div`
  position: relative;
  cursor: text;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.focus label {
    color: ${p => p.theme?.accentColor};
  }
`;

const TextAreaElement = styled.textarea`
  width: 100%;
  background-color: #eee;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 2px 2px 0 0;
  padding: 25px 12px 8px;
  outline: none;
  resize: vertical;
  font: inherit;
  font-size: 14px;

  &:focus {
    border-bottom: 1px solid ${p => p.theme?.accentColor};
  }
`;

const Label = styled.label`
  position: absolute;
  left: 0px;
  top: 0px;
  user-select: none;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  transform-origin: left top;
  transform: translate(12px, 7px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &:focus {
    border-bottom: 1px solid ${p => p.theme?.accentColor};
  }
`;

/**
 * Render a text area
 * @returns {JSX.Element} Component template
 */
const TextArea: FC<TextAreaProps> = ({value, label}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  // When formdata changes
  useEffect(() => {
    if (textAreaRef?.current && typeof value === "string") {
      textAreaRef.current.value = value;
    }
  }, [value])

  return (
    <Container className={isFocused ? "focus" : undefined}>
      <Label>{label}</Label>
      <TextAreaElement disabled ref={textAreaRef} defaultValue={value} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} aria-label={label}/>
    </Container>
  );
}

export default TextArea;